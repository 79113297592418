<template>
    <main>
        <HeaderTab :title="$t('global.titre_statistiques')"  :swiper_tabs="tabs" :swiper_action="changeTab" />
        <div id="content">
            <div class="container-fluid">

                <!-- Dernières connections entre deux date -->
               <!--  <div v-show="state == 'last_synchro'">
                    <LastSynchro/>
                </div> -->


                <!-- Chiffres de l'applications -->
                <div v-show="state == 'important_number'">
                    <ChiffresApp/>
                </div>

                <!-- Utilisateurs -->
                <div v-show="state == 'user'">
                    <UserStats/>
                </div>

                <!-- Licences par nombre de chevaux min et max -->
                <div v-show="state == 'licences_nb_horse'">
                    <LicenceHorseStats/>
                </div>

                <div v-show="state == 'new_account'">
                    <NewAccount/>
                </div>

                <div v-show="state == 'parrainage'">
                    <Parrainage/>
                </div>

            </div>
        </div>
    </main>
</template>


<script type="text/javascript">
import { EventBus } from 'EventBus';
import StatistiquesMixin from "@/mixins/Statistiques.js";
import HeaderTab from "@/components/HeaderTab";
// import LastSynchro from "@/components/Statistiques/LastSynchro";
import ChiffresApp from "@/components/Statistiques/ChiffresApp";
import UserStats from "@/components/Statistiques/UserStats";
import LicenceHorseStats from "@/components/Statistiques/LicenceHorseStats";
import NewAccount from "@/components/Statistiques/NewAccount";
import Parrainage from "@/components/Statistiques/Parrainage";
import Navigation from "@/mixins/Navigation.js";


export default {
    name: "Statistiques",
    mixins: [StatistiquesMixin, Navigation],
    data () {
        return {
            state: 'important_number',

            tabs: [
            // Les requetes sont trop lourdes, il ne faut pas activer cette vue
            // {
            //     label: 'Dernière synchronisation',
            //     href : 'last_synchro',
            //     active: true
            // },
            {
                label: 'Chiffres importants',
                href : 'important_number',
                active: true
            },
            {
                label: 'Utilisateur',
                href : 'user',
                active: false
            },
            {
                label: 'Licences',
                href : 'licences_nb_horse',
                active: false
            },
            {
                label: "Nouveaux comptes",
                href: 'new_account',
                active: false
            },
            {
                label: "Parrainage",
                href: 'parrainage',
                active: false
            }
            ],
            events_tab: {
                'Statistiques::changeTabFromName': this.changeTabFromName
            }
        }
    },
    methods: {
        changeTab(tab) {
            this.state = tab.href

            for(let ta in this.tabs){
                this.tabs[ta].active = false
            }

            tab.active = true
        },
        changeTabFromName(tabName) {
            const tab = this.tabs.filter(tab => tab.href == tabName)
            
            if(tab.length > 0) {
                this.changeTab(tab[0])
            }
        }
    },
    components: {
        HeaderTab,
        // LastSynchro,
        ChiffresApp,
        UserStats,
        LicenceHorseStats,
        NewAccount,
        Parrainage
    }
}
</script>
